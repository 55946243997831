/* App.css */
.app {
    display: flex;
    flex-direction: column;  /* Ajouté cette ligne */
    justify-content: center;
    align-items: center;
}

.main-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
}

.ps-note {
    font-weight: bold;  /* Met le texte en gras */
    text-align: left;  /* Aligne le texte à gauche */
    margin-left: auto; /* Centre le texte à gauche */
    margin-right: auto;
    font-size: 0.9em;
    margin-top: 20px;
}

.table-container {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-top: 50px;
}

.title-container {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 50px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.custom-table th, .custom-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.custom-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}
